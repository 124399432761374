<template>
  <div class="mePlan">
    <p style="text-align: left; padding-bottom: 0.1rem">自带方案：</p>
    <div class="tp">
      <el-upload
          action="#"
          list-type="picture-card"
          :auto-upload="false">
        <i slot="default" class="el-icon-plus"></i>
        <div slot="file" slot-scope="{file}">
          <img
              class="el-upload-list__item-thumbnail"
              :src="file.url" alt=""
          >
          <span class="el-upload-list__item-actions">
        <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
        >
          <i class="el-icon-zoom-in"></i>
        </span>
        <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleDownload(file)"
        >
          <i class="el-icon-download"></i>
        </span>
        <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
        >
          <i class="el-icon-delete"></i>
        </span>
      </span>
        </div>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
    </div>
    <div class="bz">
      <p>备注：</p>
      <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6}"
          placeholder="请输入内容"
          v-model="textarea2">
      </el-input>
    </div>
  </div>
</template>

<script>
export default {
  name: "mePlan",
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      textarea2: ''
    };
  },
  created() {
    if (!this.$store.state.users.CsUser.CustomerId) {return false}
    this._api.eyeTest.getTestConclusion()
    .then(res => {
      // console.log(res)
      if (res.GetListResult && res.GetListResult.length > 0) {
        let datas = res.GetListResult.map(item => {
          return this._clJson(item.S_OP_Json)
        })
        // console.log(datas)
      }
    })
  },
  methods: {
    handleRemove(file) {
      // console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      // console.log(file);
    }
  }
}
</script>

<style scoped>
  .mePlan {
    width: 90%;
    margin: 0 auto;
    padding-top: 0.3rem;
  }
  .tp {
    display: flex;
    justify-content: start;
  }
  .bz {
    text-align: left;
    padding-top: 50px;
    padding-bottom: 30px;
  }
  .bz p {padding-bottom: 10px;font-size: 17px}
</style>